import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PageComponent from '../components/Page';

function Page({ data }) {
  return <PageComponent pageData={data} />;
}

Page.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Page;

export const PageQuery = graphql`
  query Page($id: String!) {
    pages(id: { eq: $id }) {
      content {
        sliding_two_col_banner_section_timer
        sliding_two_col_banner_section {
          sliding_two_col_banner_section_gallery_items
          imageGatsby {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
            url
          }
          image
          video
          toggle_copy
          match_columns
          type
        }
        sliding_two_col_banner_mobile_section {
          sliding_two_col_banner_section_gallery_items
          imageGatsby {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
            url
          }
          image
          video
          toggle_copy
          type
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo_description
        seo_title
        header_dark
        header_over_content
        pagesections {
          clients_section_title
          contact_section_body
          contact_section_title
          featured_projects_section_title
          featured_projects_section_gallery_items
          featured_sketchbook_section_title
          intro_section_body
          three_column_text_section_body_one
          three_column_text_section_body_two
          three_column_text_section_title
          two_column_text_section_body
          two_column_text_section_title
          type
          featured_projects_section_cta_link
          featured_projects_section_cta_text
          featured_sketchbook_section_cta_link
          featured_sketchbook_section_cta_text
          contact_section_success_message
          contact_section_error_message
          projects_section_title
          sketchbook_section_title
        }
      }
    }
  }
`;
